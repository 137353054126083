// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { features } from 'src/features/features';

// The list of file replacements can be found in `angular.json`.
const mainUrl = 'https://develop.topsejur.ro';
const sentryUrl = 'https://26c9d5bc78fa4d88bfc16477b2661f97@o1427981.ingest.sentry.io/6777723';
const isActivatedSentry = true;
export const environment = {
  production: false,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: 'https://develop.topsejur.ro' + '/api',
  apiSocketUrl: mainUrl,
  mainUrl,
  sentryUrl,
  isActivatedSentry,
  // enabledSentry,
  features
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
